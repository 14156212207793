<template>
  <div class="signin-page notranslate">
    <banner :link="nav" />
    <div class="content">
      <div class="form-container">
        <hybrid-signin-form />
        <p v-if="brand !== 'assante'" class="legacy-msg">
          <span v-t>Please login from our</span>
          <a v-t :href="loginUrl">new site</a>.
          <br />
          <span v-t>This page will soon be unavailable.</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import HybridSigninForm from '~vue/components/forms/form-hybrid-signin';
import { BRAND } from '~vue/config/brand';
import { CommonDashboardSigninUrls } from '~vue/config/common-dashboard-web';
import { getSignupUrl } from '~utils/external_links';

export default {
  components: {
    Banner,
    HybridSigninForm,
  },
  data() {
    return {
      brand: BRAND,
      signupUrl: '',
    };
  },
  computed: {
    diversificationImgAlt() {
      return this.$t('typical portfolios only contain public equity and bonds, CI Digital Wealth PIP portfolios contain public equity, bonds, private equity, real estate, mortgages, alternative strategies, and preferred shares');
    },
    nav() {
      if (this.brand === 'assante') return null;

      return {
        title: this.$t('Sign Up'),
        url: this.signupUrl,
      };
    },
    loginUrl() {
      return this.$lang.slice(0, 2) === 'fr' ? CommonDashboardSigninUrls.fr : CommonDashboardSigninUrls.en;
    },
  },
  watch: {
    $lang: {
      handler(newVal) {
        this.signupUrl = getSignupUrl(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .signin-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-items: center;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 20rem;

    @media #{$screen-width-medium} {
      max-width: 20rem;
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-grow: 1;
    justify-content: space-between;
  }

  .title {
    flex: 1 0 auto;
  }

  .legacy-msg {
    margin-top: 1.25rem;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    top: 0;
    position: relative;
    min-height: 40rem;
    margin-bottom: 3rem;

    .container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      @media #{$screen-width-medium} {
        flex-direction: row;
      }
    }

    .signin {
      max-width: 300px;
    }
  }
</style>
