export function signinCallbackCtor(httpProvider, storage, window) {
  const signinCallback =  async () => {
    let response;

    try {
      response = await httpProvider.get('/api/sessions');
    } catch (e) {
      window.location = '/signin';
      return;
    }

    const user = response.data;

    storage.setItem('user', JSON.stringify({
      id: user.id,
      email: user.email,
      language: user.language,
      hasAdvisor: user.has_external_advisor
    }));

    if (window.analytics && window.analytics.alias && window.analytics.track) {
      window.analytics.alias(user.id);
      window.analytics.track('Signin', {
      category: 'User',
      label: 'Success'
      });
    }

    window.location = '/signin/continue';
  };

  return signinCallback;
}
