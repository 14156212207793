import moment from 'moment';
import numbro from 'numbro';
import numbroFr from 'numbro/languages/fr-CA';
import { getLocale } from './localization';

numbro.culture('fr', numbroFr);
numbro.culture('fr-ca', numbroFr);
numbro.culture('fr-CA', numbroFr);
const numbroEn = numbro.cultures()['en-US'];
numbro.culture('en', numbroEn);
numbro.culture('en-ca', numbroEn);
numbro.culture('en-CA', numbroEn);

export function formatDateBuilder(getLocale) {
  return (date, format) => {
    if (date === undefined || date === null) return '-';

    const locale = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    moment.locale(locale);

    const formatLookup = {
      year: {
        'en-CA': 'YYYY',
        'fr-CA': 'YYYY',
      },
      monthYear: {
        'en-CA': 'MMMM YYYY',
        'fr-CA': 'MMMM YYYY',
      },
      monthDayYear: {
        'en-CA': 'MMMM Do, YYYY',
        'fr-CA': 'D MMMM YYYY',
      },
      monthDayYearSimple: {
        'en-CA': 'MMM D, YYYY',
        'fr-CA': 'D MMM YYYY',
      },
      monthDay: {
        'en-CA': 'MMMM Do',
        'fr-CA': 'D MMMM',
      },
      monthDaySimple: {
        'en-CA': 'MMM D',
        'fr-CA': 'D MMM',
      },
    };

    return moment(date).format(formatLookup[format][locale]);
  };
}
export const formatDate = formatDateBuilder(getLocale);

// DAY MONTH //
export function formatDateMonthDayBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }
    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('D MMMM');
    }
    return moment(date).format('MMMM Do');
  };
}
export const formatDateMonthDay = formatDateMonthDayBuilder(getLocale);

export function formatDateMonthDaySimplifiedBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }
    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('D MMM');
    }
    return moment(date).format('MMM D');
  };
}
export const formatDateMonthDaySimplified = formatDateMonthDaySimplifiedBuilder(getLocale);

// MONTH DAY YEAR //
export function formatMonthDayYearBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';

    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('D MMMM YYYY');
    }
    return moment(date).format('MMMM Do YYYY');
  };
}
export const formatMonthDayYear = formatMonthDayYearBuilder(getLocale);

export function formatMonthDayYearSimplifiedBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }
    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('D MMM YYYY');
    }
    return moment(date).format('MMM D, YYYY');
  };
}
export const formatMonthDayYearSimplified = formatMonthDayYearSimplifiedBuilder(getLocale);

// MONTH DAY , YEAR //
export function formatMonthDayCommaYearBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';

    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('D MMMM YYYY');
    }
    return moment(date).format('MMMM Do, YYYY');
  };
}
export const formatMonthDayCommaYear = formatMonthDayCommaYearBuilder(getLocale);

// MONTH DAY //
export function formatMonthDayWithoutYearBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('D MMMM');
    }
    return moment(date).format('MMM D');
  };
}
export const formatMonthDayWithoutYear = formatMonthDayWithoutYearBuilder(getLocale);

// MONTH YEAR //
export function formatMonthYearBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';

    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('MMMM YYYY');
    }
    return moment(date).format('MMMM YYYY');
  };
}
export const formatMonthYear = formatMonthYearBuilder(getLocale);

// YEAR //
export function formatYearBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    if (format === 'fr-CA') {
      moment.locale(format);
      return moment(date).format('YYYY');
    }
    return moment(date).format('YYYY');
  };
}
export const formatYear = formatYearBuilder(getLocale);

// MONTH //
export function formatMonthBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('MMMM');
    }
    return moment(date).format('MMMM');
  };
}
export const formatMonth = formatMonthBuilder(getLocale);

// MONTH DAY YEAR, HOUR:MINUTE am/pm //
export function formatMonthDayYearCommaTimeBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';

    moment.locale(format);
    if (format === 'fr-CA') {
      return moment(date).format('D MMMM YYYY, h:mma');
    }
    return moment(date).format('MMMM D YYYY, h:mma');
  };
}
export const formatMonthDayYearCommaTime = formatMonthDayYearCommaTimeBuilder(getLocale);

// HOUR:MINUTE am/pm //
export function formatHourMinuteTimeBuilder(getLocale) {
  return date => {
    if (date === undefined || date === null) {
      return '-';
    }

    const format = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';

    moment.locale(format);
    return moment(date).format('h:mma');
  };
}
export const formatHourMinuteTime = formatHourMinuteTimeBuilder(getLocale);

// $1587 -> $1.6K //
export function formatCurrencyWithMantissaBuilder(getLocale) {
  return number => {
    if (number === undefined || number === null) {
      return '-';
    }

    const locale = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-US';
    numbro.culture(locale);

    return numbro(+number).formatCurrency('(0[.]0a)');
  };
}
export const formatCurrencyWithMantissa = formatCurrencyWithMantissaBuilder(getLocale);

// PERCENTAGE //
export function formatPercentageBuilder(getLocale) {
  return (percentage, options = {}) => {
    const settings = { zero: false };

    Object.assign(settings, options);
    if ((percentage === null || percentage === undefined) && !settings.zero) {
      return '-';
    }

    const locale = getLocale() === 'fr-CA' ? 'fr-CA' : 'en-CA';
    numbro.culture(locale);

    if (locale === 'fr-CA') {
      if (+percentage === 0 || settings.decimals === 0) settings.format = '0 %'
      else settings.format = '0.00 %'
    } else {
      if (+percentage === 0 || settings.decimals === 0) settings.format = '0%'
      else settings.format = '0.00%'
    }

    return numbro(+percentage / 100).format(settings.format);
  };
}
export const formatPercentage = formatPercentageBuilder(getLocale);

// QUANTITY //
//          12.34 -> '12.34' (English) or '12,34' (French)    when DEFAULT
//          12.34 -> '12.340' (English) or '12,340' (French)  when minimumFractionDigits: 3
//          12.34 -> '12 (English and French)                 when minimumFractionDigits: 0
//          0     -> '-' (English and French)
export function formatQuantityBuilder(getLocale) {
  return (quantity, settings) => {
    const settingsWithDefaults = Object.assign({ zero: '-', minimumFractionDigits: 0, maximumFractionDigits: 3 }, settings);
    if (quantity === null || quantity === undefined) return '-';

    const number = (typeof quantity === 'number') ? quantity : parseFloat(quantity);
    if (number === 0 && settingsWithDefaults.zero !== null) return settingsWithDefaults.zero;

    const locale = getLocale() === 'fr-CA' ? 'fr-CA' : 'en';

    return number.toLocaleString(locale, {
      minimumFractionDigits: settingsWithDefaults.minimumFractionDigits,
      maximumFractionDigits: settingsWithDefaults.maximumFractionDigits,
    });
  };
}
export const formatQuantity = formatQuantityBuilder(getLocale);

// INTEGER //
//          12.34 -> '12'
//          0     -> '-'
export function formatIntegerFromFloatBuilder(getLocale) {
  return (float, settings) => {
    const settingsWithDefaults = Object.assign({ zero: '-' }, settings);
    if (float === null || float === undefined) return '-';

    let number = (typeof quantity === 'number') ? float : parseFloat(float);
    if (number === 0 && settingsWithDefaults.zero !== null) return settingsWithDefaults.zero;
    // We cannot pass in { maximumFractionDigits: 0 } to formatQuantity because it will lead
    // to unintentional rounding of the integer when the decimal is >= .5
    number -= (number % 1);

    const locale = getLocale() === 'fr-CA' ? 'fr-CA' : 'en';
    return number.toLocaleString(locale);
  };
}
export const formatIntegerFromFloat = formatIntegerFromFloatBuilder(getLocale);

// FRACTION //
//          12.34 -> '.34' (English) or ',34' (French)    when DEFAULT
//          12    -> '.340' (English) or ',340' (French)  when minimumFractionDigits: 3
//          12    -> '' (English and French)              when minimumFractionDigits: 0
//          0     -> '' (English and French)
export function formatFractionFromFloatBuilder() {
  return (float, settings) => {
    const settingsWithDefaults = Object.assign({ zero: '-', minimumFractionDigits: 0, maximumFractionDigits: 3 }, settings);
    if (float === null || float === undefined) return '-';

    let number = (typeof quantity === 'number') ? Math.abs(float) : Math.abs(parseFloat(float));
    if (number === 0 && settingsWithDefaults.zero !== null) return settingsWithDefaults.zero;

    number %= 1;

    const locale = getLocale() === 'fr-CA' ? 'fr-CA' : 'en';
    return number.toLocaleString(locale, settingsWithDefaults).substring(1);
  };
}
export const formatFractionFromFloat = formatFractionFromFloatBuilder(getLocale);

// CURRENCY //
export function formatCurrencyBuilder(getLocale) {
  return (amount, options = {}) => {
    const settings = {
      decimals: 2,
      zero: null,
    };

    Object.assign(settings, options);

    if (amount == null || (+amount === 0 && settings.zero)) return settings.zero;
    const amountInteger = +amount;
    const currencyStyle = { style: 'currency', currency: 'CAD', minimumFractionDigits: settings.decimals };
    const currency = amountInteger.toLocaleString(getLocale(), currencyStyle);
    return currency;
  };
}
export const formatCurrency = formatCurrencyBuilder(getLocale);

// PHONE NUMBER //
export function phoneNumber(phone) {
  const number = phone.replace(/[^\d]/g, '');
  if (number.length === 11) {
    return number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
  } else if (number.length === 10) {
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  } else if (number.length === 7) {
    return number.replace(/(\d{3})(\d{4})/, '$1-$2');
  }
  return phone;
}

// CAPITALIZE //
export function capitalize(value) {
  if (!value) return '';
  const capString = value.toString();
  return capString.charAt(0).toUpperCase() + capString.slice(1);
}

export function humanize(value) {
  if (!value) return '';

  if (typeof value === "string") {
    return value.replace(/_/g, ' ').replace(/[A-Z]+/g, function ($1, offset) {
      if (offset === 0) {
        return $1;
      } else if ($1.length > 1) {
        return $1;
      } else {
        return $1.toLowerCase();
      }
    });
  }
  return value;
};

export default {
  formatDate,
  formatDateMonthDay,
  formatDateMonthDaySimplified,
  formatMonthDayWithoutYear,
  formatMonthYear,
  formatMonthDayYear,
  formatMonthDayYearSimplified,
  formatMonthDayCommaYear,
  formatYear,
  formatMonth,
  formatCurrencyWithMantissa,
  formatPercentage,
  formatQuantity,
  formatIntegerFromFloat,
  formatFractionFromFloat,
  formatCurrency,
  formatMonthDayYearCommaTime,
  formatHourMinuteTime,
  phoneNumber,
  capitalize,
  humanize
};
