<template>
  <error-wrapper>
    <p>
      <span v-t>The email address used to sign up already exists. Please link your CI Digital Wealth account or contact</span>
      <a href="mailto:info@ciis.ca">info@ciis.ca</a>
      <span v-t>with your full name, email address used to sign up, and subject line outlining the problem.</span>
    </p>
  </error-wrapper>
</template>

<script>
import ErrorWrapper from './error-wrapper';

export default {
  components: {
    'error-wrapper': ErrorWrapper
  }
};
</script>