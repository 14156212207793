<template>
  <div class="page notranslate logged-out">
    <banner nav-public />
    <main class="content">
      <h2 v-t>You've been logged out.</h2>
      <div class="layout">
        <div class="form-container">
          <div>
            <hybrid-signin-form :title="title" />
          </div>
        </div>
        <div class="mobile-app">
          <div class="mobile-text">
            <h4 v-t class="miss-us">Miss us already?</h4>
            <p v-t class="get-mobile-app">Get the mobile app.</p>
            <div class="stores">
              <span class="small">Coming soon</span>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-component @language-changed="languageChanged" />
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import Footer from '@layoutTheme/footer';
import locale from 'utils/locale';
import HybridSigninForm from '~vue/components/forms/form-hybrid-signin';
import { BRAND } from '~vue/config/brand';
import { CommonDashboardSigninUrls } from '~vue/config/common-dashboard-web';

let iosUrl;
let androidUrl;
if (BRAND === 'wealthbar') {
  iosUrl = 'https://itunes.apple.com/us/app/wealthbar/id978421974?mt=8';
  androidUrl = 'https://play.google.com/store/apps/details?id=com.wealthbar.android&amp;hl=en';
} else {
  iosUrl = 'https://itunes.apple.com/app/id1453910096';
  androidUrl = 'https://play.google.com/store/apps/details?id=com.assante.android&hl=en';
}

export default {
  components: {
    Banner,
    'footer-component': Footer,
    HybridSigninForm,
  },
  data() {
    return {
      language: null,
      iosUrl,
      androidUrl,
      title: { en: 'Sign back in', fr: 'Se connecter de nouveau' },
      brand: BRAND,
    };
  },
  computed: {
    loginUrl() {
      return this.$lang.slice(0, 2) === 'fr' ? CommonDashboardSigninUrls.fr : CommonDashboardSigninUrls.en;
    },
  },
  created() {
    this.language = locale.getLanguage();
  },
  methods: {
    languageChanged(newLanguage) {
      this.language = newLanguage;
    },
  },
};
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content {
    margin-top: 2rem;
    color: $neutral-900;

    h2 {
      text-align: center;
    }

    @media #{$screen-width-large} {
      margin: 1rem 0 1rem;
    }

    .layout {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      @media #{$screen-width-large} {
        flex-direction: row;
        margin: 4rem 0 3.5rem;
      }

      .form-container {
        width: 100%;
        padding-bottom: 2.5rem;
        border-bottom: 2px solid $neutral-100;

        @media #{$screen-width-large} {
          width: 50%;
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          padding-right: 3rem;
          padding-bottom: 1rem;
          border-bottom: none;
          border-right: 2px solid $neutral-100;
        }

        > div {
          width: 90%;
          max-width: 20rem;
          margin: 0 auto;

          @media #{$screen-width-large} {
            margin: 0;
          }
        }
      }
    }
  }

  .legacy-msg {
    margin-top: 1.25rem;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .mobile-app {
    width: 100%;
    margin: 2.5rem 0;
    max-width: 22rem;
    min-height: 5.625rem;

    @media #{$screen-width-large} {
      width: 50%;
      margin-top: 0;
      padding-left: 3rem;
      max-width: inherit;
    }

    .mobile-image {
      width: 18rem;
      height: 18rem;
      position: relative;
      left: -2rem;

      @media #{$screen-width-large} {
        position: inherit;
        left: inherit;
      }
    }

    .mobile-text {
      position: absolute;
      left: 30%;
      top: 0rem;
      text-align: center;

      @media #{$screen-width-large} {
        left: 8rem;
      }

      .miss-us {
        margin: 0;
      }

      .get-mobile-app {
        margin: 0.25rem 0 0.5rem;
      }

      .stores {
        text-align: center;
      }
    }
  }
  a.icon.xlarge svg {
    width: 2rem;
    height: 2rem;
  }
</style>
