<template>
  <footer id="footer" role="contentinfo" class="notranslate">
    <div class="container">
      <nav class="sitemap">
        <section>
          <h5 v-t>About</h5>
          <ul>
            <li>
              <a href="/about" target="_self" v-t>Who We Are</a>
            </li>
            <li>
              <a href="/careers" target="_self" v-t>Careers</a>
            </li>
            <li>
              <a href="/contact" target="_self" v-t>Contact</a>
            </li>
          </ul>
        </section>
        <section>
          <h5 v-t>Why CI Digital Wealth</h5>
          <ul>
            <li>
              <a href="/investments" target="_self" v-t>Investments</a>
            </li>
            <li>
              <a href="/pricing" target="_self" v-t>Pricing</a>
            </li>
            <li>
              <a href="/security" target="_self" v-t>Security</a>
            </li>
          </ul>
        </section>
        <section>
          <h5 v-t>Resources</h5>
          <ul>
            <li>
              <a href="/tools" target="_self" v-t>Tools</a>
            </li>
          </ul>
        </section>
        <section>
          <h5 v-t>Legal</h5>
          <ul>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
          </ul>
        </section>
      </nav>
      <slot></slot>
      <section class="footer-info">
        <section style="width: 150px; box-align:right; float:right;">
          <p class="footer-ciro">
            <img src="/CIRO_OCRI_Logo_English.svg" height="52" width="192" />
            <img src="/cipf-logo-colour.png" height="56" width="75" />
          </p>
        </section>
        <section style="width: 1070px;">
          <p class="subtle">
            <small>CI Digital Wealth, a registered business name of CI Investment Services Inc. ("CIIS"), an order-execution only discount brokerage for the self-directed investor who does not require any financial advice. CI Investment Services Inc. is a registered investment dealer under the securities legislation in all provinces and territories in Canada, and member of the Canadian Investor Protection Fund and Canadian Investment Regulatory Organization.</small>
          </p>
          <p class="subtle">
            <small>&#8224;</small>
          </p>
        </section>
        <section>
          <p class="center">
            <small>&copy; {{ new Date().getFullYear() }}</small>
            <small v-t>CI Digital Wealth All rights reserved.</small>
          </p>
        </section>
      </section>
    </div>
  </footer>
</template>
<script>
import Locale from 'utils/locale';

export default {
  created() {
    this.language = Locale.getLanguage();
  },
  data() {
    return {
      language: null,
    };
  },
  methods: {
    toggleLanguage() {
      if (Locale.getLanguage() === 'english') {
        this.language = 'french';
      } else {
        this.language = 'english';
      }
      Locale.setLanguage(this.language);
      this.$emit('language-changed', this.language);
    },
  },
};
</script>

<style lang="scss" scoped>
footer[role="contentinfo"] {
  background-color: $bg-50;
  padding: 2rem 0 1rem;

  ul { list-style: none; }

  a {
    color: #37373f;
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  @media #{$screen-width-medium} { padding: 3rem 0 1rem; }

  .sitemap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media #{$screen-width-medium} {
      justify-content: space-around;
      flex-wrap: nowrap;
    }

    section {
      text-align: left;
      flex: 1 1 50%;
      min-width: 8rem;
    }
  }

  .setting-toggle {
    display: inline-block;
    padding: 0;
    color: #37373f;
    border: 1px solid #37373f;
    background: transparent;
    border-radius: 1rem;
    text-decoration: none;
    line-height: 1.375rem;
    cursor: pointer;
    span {
      padding: 0 0.5rem;
      border-radius: 1rem;
      display: inline-block;
      &:first-child { padding-right: 0; }
      &:last-child { padding-left: 0; }
      &[data-active="true"] {
        background: #37373f;
        color: #ffffff;
        padding: 0 0.5rem;
      }
    }
  }

  .footer-external {
    text-align: left;

    @media #{$screen-width-medium} {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      section {
        flex: 0 1 25%;
        padding: 1rem 0;
      }
    }

    ul {
      display: flex;
      justify-content: flex-start;
    }

    li {
      flex: 0 1 2rem;
      padding-right: 0.5rem;
    }

    a {
      width: 2rem;
      height: 2rem;
      svg {
        width: 2rem;
        height: 2rem;
        overflow: visible;
      }
    }
  }

  .footer-info { padding: 1rem 0; }
}

</style>
