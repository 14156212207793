<template>
  <header role="banner" :data-collapsed="isMenuCollapsed ? 'true' : 'false'" class="notranslate">
    <nav id="nav-banner" :class="theme">
      <div class="container-banner">
        <a :href="[loggedIn ? '/app' : '/']" target="_self">
          <img src="./images/digitalwealth_logo_color.png" width="140" height="80">
        </a>
        <a v-if="!navPublic && link" class="banner-link" target="_self" :href="link.url">{{link.title}}</a>
        <ul v-if="navPublic" class="small-screen-hide">
          <li v-if="link">
            <a class="banner-link" target="_self" :href="link.url">{{link.title}}</a>
          </li>
          <li>
            <a href="/pricing" class="banner-link" :class="{ active: atLocation('/pricing') }" target="_self" v-t>Pricing</a>
          </li>
          <li>
            <a href="/investments" class="banner-link" :class="{ active: atLocation('/investments') }" target="_self" v-t>Investments</a>
          </li>
          <li>
            <a href="/signin" class="banner-link" target="_self" v-t>Sign&nbsp;in</a>
          </li>
          <li>
            <a class="button" :href="signupUrl" target="_self" v-t>Start Investing</a>
          </li>
        </ul>
        <ul v-if="navPublic" class="large-screen-hide">
          <li>
            <a href="/signin" class="banner-link" target="_self" v-t>Sign&nbsp;in</a>
          </li>
          <li>
            <button type="button" id="nav-dropdown-toggle" class="flat icon" @click="toggleMenu">
              <svg  version="1.1" viewBox="0 0 48 48" height="12" width="12" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <g id="hamburger" fill="#303030">
                  <rect x="0" y="2" width="48" height="4"></rect>
                  <rect x="0" y="22" width="48" height="4"></rect>
                  <rect x="0" y="42" width="48" height="4"></rect>
                </g>
                <polygon id="close" points="45 0 24 21 3 0 0 3 21 24 0 45 3 48 24 27 45 48 48 45 27 24 48 3"></polygon>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </nav>
    <nav v-if="navPublic" id="nav-dropdown" class="large-screen-hide">
      <ul>
        <li v-if="link" >
          <a class="menu-link" :href="link.url" target="_self" >{{link.title}}</a>
        </li>
        <li>
          <a class="menu-link" href="/pricing" :class="{ active: atLocation('/pricing') }" target="_self" v-t>Pricing</a>
        </li>
        <li>
          <a class="menu-link" href="/investments" :class="{ active: atLocation('/investments') }" target="_self" v-t>Investments</a>
        </li>
        <li>
          <a class="button" :href="signupUrl" target="_self" v-t>Start Investing</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { getSignupUrl } from '~utils/external_links';

export default {
  props: {
    theme: {
      type: String,
      default: '',
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      validator: obj => obj.title && obj.url,
      default: null,
    },
    navPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenuCollapsed: true,
      signupUrl: '',
    };
  },
  watch: {
    $lang: {
      handler(newVal) {
        this.signupUrl = getSignupUrl(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
    atLocation(location) {
      return !!window.location.pathname.match(location);
    },
  },
};
</script>
