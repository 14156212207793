<template>
  <div class="page notranslate">
    <banner />
    <main class="container">
      <section id="valet-landing-page">
        <div class="advisor-greeting">
          <img v-if="advisor.photoUrl" :class="{'advisor-photo':!isImageLogo,'advisor-logo':isImageLogo}" :alt="photoAlt" :src="advisor.photoUrl" />
          <span v-else class="advisor-photo initial">
            {{ advisor.firstName.charAt(0) + advisor.lastName.charAt(0) }}
          </span>
          <h2 v-t>Welcome!</h2>
          <h4>{{ referralIntro }}</h4>
          <div class="contact small-screen-hide">
            <p>{{ contactCopy }}</p>
            <a :href="`mailto::${advisor.email}`" class="dark">
              <svg viewBox="0 0 32 32">
                <path d="M27,6H5C3.9,6,3,6.9,3,8v16c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2V8C29,6.9,28.1,6,27,6z M25.4,8L16,16.6L6.6,8H25.4z M5,24 V9.3l11,10.1L27,9.3L27,24H5z"/>
              </svg>{{ advisor.email }}
            </a>
            <a :href="`tel:${advisor.phone}`" class="dark">
              <svg viewBox="0 0 32 32">
                <path d="M23.1,29L23.1,29c-0.5,0-1-0.1-1.5-0.2c-9.1-2.3-16.2-9.4-18.5-18.5c-0.5-2,0.1-4.2, 1.6-5.7l1.8-1.8c1.1-1.1,3.1-1.1,4.2,0 l3.5,3.5c0.6,0.6,0.9,1.3,0.9,2.1c0,0.8-0.3,1.6-0.9,2.1l-1.6,1.6c-0.3, 0.3-0.4,0.9-0.1,1.3c1.6,2.3,3.6,4.3,5.9,5.9 c0.4,0.3,1,0.2,1.3-0.1l1.6-1.6c1.1-1.1,3.1-1.1,4.2,0l3.5, 3.5c1.2,1.2,1.2,3.1,0,4.2l-1.8,1.8C26.3,28.4,24.7,29,23.1,29z M8.6,4 C8.4,4,8.1,4.1,7.9,4.3L6.2,6c-1,1-1.4,2.4-1.1,3.8c2.1,8.4,8.6,14.9,17.1,17.1c0.3,0.1,0.6,0.1,1,0.1l0,0c1.1,0,2.1-0.4,2.8-1.2 l1.8-1.8c0.4-0.4,0.4-1,0-1.4l-3.5-3.5c-0.4-0.4-1-0.4-1.4,0l-1.6,1.6c-1,1-2.7,1.2-3.9,0.3c-2.5-1.8-4.6-3.9-6.4-6.4 c-0.9-1.2-0.7-2.8,0.3-3.9l1.6-1.6c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L9.3,4.3C9.2,4.1,8.9,4,8.6,4z"/>
              </svg>{{ advisor.phone | phoneNumber }}
            </a>
          </div>
        </div>

        <div class="valet-client-signup">
          <h2 v-t>Sign up now</h2>
          <signup-form
            :name-entry="true"
            :signup-button="buttonText"
            :partner-channel="advisor.partnerChannel"
            @auth-redirect="advisorLink"
          />
          <p v-if="error" class="neg">
            <span v-t>Something went wrong. Please try again later, or </span>
            <a v-t v-intercom>contact us</a>
            <span v-t> if the issue persists.</span>
          </p>
        </div>

        <div class="contact large-screen-hide">
          <p>
            {{ contactCopy }}
          </p>
          <a :href="`mailto::${advisor.email}`" class="dark">
            <svg viewBox="0 0 32 32">
              <path d="M27,6H5C3.9,6,3,6.9,3,8v16c0,1.1,0.9,2,2,2h22c1.1,0,2-0.9,2-2V8C29,6.9,28.1,6,27,6z M25.4,8L16,16.6L6.6,8H25.4z M5,24 V9.3l11,10.1L27,9.3L27,24H5z"/>
            </svg>{{ advisor.email }}
          </a>
          <a :href="`tel:${advisor.phone}`" class="dark">
            <svg viewBox="0 0 32 32">
              <path d="M23.1,29L23.1,29c-0.5,0-1-0.1-1.5-0.2c-9.1-2.3-16.2-9.4-18.5-18.5c-0.5-2,0.1-4.2, 1.6-5.7l1.8-1.8c1.1-1.1,3.1-1.1,4.2,0 l3.5,3.5c0.6,0.6,0.9,1.3,0.9,2.1c0,0.8-0.3,1.6-0.9,2.1l-1.6,1.6c-0.3, 0.3-0.4,0.9-0.1,1.3c1.6,2.3,3.6,4.3,5.9,5.9 c0.4,0.3,1,0.2,1.3-0.1l1.6-1.6c1.1-1.1,3.1-1.1,4.2,0l3.5, 3.5c1.2,1.2,1.2,3.1,0,4.2l-1.8,1.8C26.3,28.4,24.7,29,23.1,29z M8.6,4 C8.4,4,8.1,4.1,7.9,4.3L6.2,6c-1,1-1.4,2.4-1.1,3.8c2.1,8.4,8.6,14.9,17.1,17.1c0.3,0.1,0.6,0.1,1,0.1l0,0c1.1,0,2.1-0.4,2.8-1.2 l1.8-1.8c0.4-0.4,0.4-1,0-1.4l-3.5-3.5c-0.4-0.4-1-0.4-1.4,0l-1.6,1.6c-1,1-2.7,1.2-3.9,0.3c-2.5-1.8-4.6-3.9-6.4-6.4 c-0.9-1.2-0.7-2.8,0.3-3.9l1.6-1.6c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L9.3,4.3C9.2,4.1,8.9,4,8.6,4z"/>
            </svg>{{ advisor.phone | phoneNumber }}
          </a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import Intercom from '~vue/directives/intercom';
import { http } from '~vue/helpers/http';
import SignupForm from '~vue/components/forms/form-signup';
import { BRAND, BRAND_FULL_NAME } from '~vue/config/brand';
import { phoneNumber, capitalize } from '~vue/helpers/filters';

// Todo extract this into a service. Remove VueResource entirely
async function getAdvisor(slug) {
  const apiUrl = `/advisor_api/advisor_slugs/${slug}`;
  const response = await http.get(apiUrl);
  return {
    id: response.data.id,
    firstName: response.data.first_name,
    lastName: response.data.last_name,
    phone: response.data.phone,
    email: response.data.email,
    photoUrl: response.data.photo_url,
    defaultFee: response.data.default_fee,
    partnerChannel: response.data.partner_channel,
  };
}

export default {
  directives: { Intercom },
  components: {
    Banner,
    SignupForm,
  },
  filters: {
    phoneNumber,
    capitalize,
  },
  data() {
    return {
      brand: BRAND,
      brandFullName: BRAND_FULL_NAME,
      advisor: {
        id: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        photoUrl: '',
        defaultFee: null,
        partnerChannel: '',
      },
      error: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const advisor = await getAdvisor(to.params.slug);

      next((vm) => { vm.advisor = advisor; });
    } catch (err) {
      // Todo: This should only apply to 404 errors, other errors should show
      // a different message
      next('/representative-invitation/invalid');
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      this.advisor = await getAdvisor(to.params.slug);
      next();
    } catch (err) {
      // Todo: This should only apply to 404 errors, other errors should show
      // a different message
      next('/representative-invitation/invalid');
    }
  },
  computed: {
    buttonText() {
      return this.$t('Create Account');
    },
    referralIntro() {
      return this.$t(
        'Create an account with %1 and invest online with %2 %3\'s help',
        [this.brandFullName, this.advisor.firstName, this.advisor.lastName],
      );
    },
    contactCopy() {
      return this.$t('If you have any questions, contact %1:', [this.advisor.firstName]);
    },
    photoAlt() {
      return this.$t('photo for %1 %2', [this.advisor.firstName, this.advisor.lastName]);
    },
    isImageLogo() { return this.advisor.photoUrl ? this.advisor.photoUrl.includes('advisor_logo') : false; },
  },
  async created() {
    try {
      await http.get('/advisor_api/advisor');
    } catch (e) {
      await http.delete('/api/sessions');
    }
  },
  methods: {
    async advisorLink() {
      this.error = false;
      try {
        const response = await http.post('/advisor_api/referral_invitation', { advisor_id: this.advisor.id });
        window.location = `/app/representative-link?token=${response.data.invitation_token}`;
      } catch (error) {
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  input.error { border-color: $neg-500; }

  .page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
  }

  #valet-landing-page {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
    @media #{$screen-width-medium} {
      flex-flow: row nowrap;
    }
  }

  .advisor-greeting {
    text-align:center;
    margin: 2rem auto;
    max-width: 28rem;
    border-bottom: 1px solid $neutral-300;
    @media #{$screen-width-medium} {
      flex: 1 2 auto;
      margin: 0 2rem;
      border: none;
    }
  }

  .advisor-logo, .advisor-photo {
    flex: 0 0 auto;
    display: inline-block;
    vertical-align: middle;
    &.initial {
      background: $primary-300;
      color: $white;
      text-align: center;
      vertical-align: baseline;
      text-transform: uppercase;
      font-size: 3rem;
      line-height: 6rem;
      @media #{$screen-width-medium} {
        font-size: 4.5rem;
        line-height: 9rem;
      }
    }
  }

  .advisor-logo {
    width: auto;
    height: auto;
    max-height: 15rem;
    object-fit: contain;
  }

  .advisor-photo {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    @media #{$screen-width-medium} {
      width: 9rem;
      height: 9rem;
    }
  }

  .valet-client-signup {
    padding-bottom: 2rem;
    @media #{$screen-width-medium} {
      flex: 1 1 auto;
      border-top: none;
      border-left: 1px solid $neutral-300;
      padding: 0 2rem 2rem;
    }
  }

  .contact {
    position: inherit;
    border-top: 1px solid $neutral-300;
    width: 100%;
    padding: 1rem;
    text-align: center;

    @media #{$screen-width-medium} { border-top: none; }
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      display: inline-block;
      vertical-align: middle;
    }
  }
</style>
